<template>
  <div class="forcedDeliveryBox">
    <p class="title">仪器数据上传</p>
    <el-upload
      class="upload-demo"
      :headers="headers"
      drag
      name="file"
      :on-success="handleAvatarSuccess"
      :action="action"
      multiple
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div class="el-upload__tip" slot="tip">
        可以上传.csv、.xls、.xls文件、<br>
        文件名必须为《开机自检数据》，《定标检测数据》，《仪器日志记录》，《样本检测数据》
      </div>
    </el-upload>
  </div>
</template>
  
  <script>
import apiDetail from '@/api/other.js'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      headers: '',
      action: `${process.env.VUE_APP_BASE_API}/api/upload_ins_file/`,
    }
  },
  created() {
    this.headers = {
      Authorization: `${getToken()}`,
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      if(res.code != 200){
        Message.warning(res.msg)
      }
    },
  },
}
</script>
  <style lang="scss" scoped>
.forcedDeliveryBox {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
}
</style>